import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { colors } from '../consts/style';

const Tabs = styled.div`
  width: 100%;
`;

const TabsLink = styled.div`
  width: 100%;
  margin: 0 0 40px;
  display: flex;
  border-bottom: 1px solid ${colors.gray3};

  span {
    padding: 0 0 16px;
    margin: 0;
    font-weight: 600;
    color: ${colors.gray2};
    cursor: pointer;
    position: relative;
    top: 1px;
    font-size: 16px;
    line-height: 22px;
    min-width: 70px;
    text-align: center;
    flex: 1 1 100%;

    &.active {
      color: ${colors.black2};
      border-bottom: 1px solid ${colors.black2};
    }

    &:hover {
      color: ${colors.black2};
    }
  }
`;

const TabsContent = styled.div`
  width: 100%;
  position: relative;

  ul {
    opacity: 0;
    transform: translateY(-9999em);
    position: absolute;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      transform: translateY(0);
      position: relative;
      visibility: visible;
    }

    li {
      margin: 0 0 16px;
      line-height: 1;
    }

    a {
      color: ${colors.ramotionBlue};
      font-size: 16px;
      line-height: 22px;
      text-decoration: none;

      &:hover {
        color: ${colors.ramotionBlueHover};
      }

      svg {
        margin: 0 8px 0 0;
        position: relative;
        top: -2px;
      }
    }
  }
`;

export default function TabsWrapper({ pageTag, postTags, indexInCollection }) {
  const data = useStaticQuery(tabsQuery);

  const tabs = data.tabs.nodes;

  const tagsSlugs = [];

  pageTag ? tagsSlugs.push(pageTag) : postTags.forEach(item => {
      tagsSlugs.push(item.slug);
    });

  const compareTags = (tabLink, tagsSlugs) => {
    let tablinkSlugs = [];

    tabLink.forEach(item => {
      item.tag.forEach(item => {
        tablinkSlugs.push(item.slug);
      });
    });

    return tablinkSlugs.some(item => tagsSlugs.includes(item));
  };

  const setActive = e => {
    let target = e.target.getAttribute('data-tab-index');

    let allTabs = document.querySelectorAll(`[data-tab-index]`);
    let activeTabs = document.querySelectorAll(`[data-tab-index='${target}']`);

    allTabs.forEach(item => {
      item.classList.remove('active');
    });

    activeTabs.forEach(item => {
      item.classList.add('active');
    });
  };

  useEffect(() => {
    let allTabs = document.querySelectorAll(`[data-tab-index]`);
    let firstTabsAttr = allTabs[0].getAttribute('data-tab-index');

    if (firstTabsAttr !== 0) {
      let firstTabs = document.querySelectorAll(
        `[data-tab-index='${firstTabsAttr}']`
      );

      firstTabs.forEach(item => {
        item.classList.add('active');
      });
    }
  }, []);

  return <Tabs>
    <TabsLink>
      {tabs.map((item, i) =>
        compareTags(item.tabLink, tagsSlugs) ? (
          <span
            data-tab-index={i}
            onClick={setActive}
            className={i === 0 ? 'active' : ''}
            key={item.id}
          >
            {item.title}
          </span>
        ) : null
      )}
    </TabsLink>
    <TabsContent>
      {tabs.map((item, i) =>
        compareTags(item.tabLink, tagsSlugs) ? (
          <ul
            data-tab-index={i}
            className={i === 0 ? 'active' : ''}
            key={item.id}
          >
            {item.tabLink.map((item) =>
              item.tag.some(tag => tagsSlugs.includes(tag.slug)) ? (
                <li key={item.id}>
                  <a href={item.link}>
                    <svg
                      width="5"
                      height="8"
                      viewBox="0 0 5 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 4L3.49691e-07 8L0.5 4L0 2.18557e-07L5 4Z"
                        fill="#A8A8A8"
                      />
                    </svg>
                    {item.anchor[indexInCollection % item.anchor.length].anchor}
                  </a>
                </li>
              ) : null
            )}
          </ul>
          
        ) : null
      )}
    </TabsContent>
  </Tabs>
;}

const tabsQuery = graphql`
  {
    tabs: allDatoCmsAsideWidgetNew(sort: { fields: position }) {
      nodes {
        id
        title
        position
        tabLink {
          anchor {
            anchor
          }
          link
          id
          tag {
            slug
          }
        }
      }
    }
  }
`;
